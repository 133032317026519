/**
 * Set an attribute with a a value on a particular element
 *
 * @param {element} elem  the element to set the attribute on
 * @param {string} attribute  the attribute to put a value in
 * @param {string} val  the value to add to the attribute
 */
export function setElemAttribute (elem, attribute, val) {
    if (elem.getAttribute(attribute) !== `${val}`) {
        elem.setAttribute(attribute, val);
    }
}

/**
 * Work out the co-ordinates/position on screen of an element
 * @param  {element} elem  the element to find
 *
 * @return {object}  the position of the element in an object
 */
export function getCoordinates (elem) {
    const bounds = elem.getBoundingClientRect();

    return { left: bounds.left, top: bounds.top };
}

/**
 * Take an array of elements and order them based on top and left position
 * @param  {array} elemsArray  the array of elements to sort
 *
 * @return {array}  the sorted array
 */
export function sortElementsByCoordinates (elemsArray) {
    return elemsArray.sort(function (a, b) {
        const aOffset = getCoordinates(a);
        const bOffset = getCoordinates(b);

        if (aOffset.top === bOffset.top) {
            if (aOffset.left === bOffset.left) {
                return 0;
            }

            return (aOffset.left < bOffset.left) ? -1 : 1;
        }

        return (aOffset.top < bOffset.top) ? -1 : 1;
    });
}
