$(function () {
    var loaderColors = ['#c81e6e', '#87005F', '#00a1ca'];
    var colorIndex = 0;
    // the below code needs to be refactored
    var loaderPath = document.querySelector('.loader-path');
    if (loaderPath) {
        setTimeout(function () {
            document.querySelector('.loader-path').setAttribute('stroke', '#87005F');
            setTimeout(function () {
                document.querySelector('.loader-path').setAttribute('stroke', '#00a1ca');
                loaderColorChange();
            }, 2000);
        }, 2000);
    }

    function loaderColorChange () {
        setInterval(function () {
            document.querySelector('.loader-path').setAttribute('stroke', loaderColors[colorIndex]);
            colorIndex = (colorIndex + 1) % loaderColors.length;
        }, 2000);
    }
});
