let validAgentPromoCode = false;
let agentPromoCode;
document.addEventListener('DOMContentLoaded', function () {
    agentPromoCode = document.querySelector('.promo-code');
    if (agentPromoCode !== null) {
        agentPromoCode.addEventListener('focus', function () {
            removeClass('.agent-promo-section .error-class', 'show-error-class');
        });
        agentPromoCode.addEventListener('keyup', function () {
            onKeyUpPromoCode();
        });
        agentPromoCode.addEventListener('blur', function () {
            onBlurPromoCode();
        });
    }
});
// on blur event
function onBlurPromoCode () {
    validateForm();
    if (!validAgentPromoCode) {
        agentPromoCode.classList.add('invalid-error-class');
        agentPromoCode.classList.remove('focus-class');
        addClass('.agent-promo-section .error-class', 'show-error-class');
        addClass('.agent-promo-section .promo-code-applied', 'hide');
        document.querySelector('#promo-code-invalid .promo-id').innerHTML = agentPromoCode.value;
        if (agentPromoCode.value !== '') {
            agentPromoCode.value = '';
        }
    } else {
        agentPromoCode.classList.remove('invalid-error-class');
        agentPromoCode.classList.add('focus-class');
        removeClass('.agent-promo-section .error-class', 'show-error-class');
    }
}
// on keyup event
function onKeyUpPromoCode () {
    validateForm();
    changeButtonState();
}
// validate form
function validateForm () {
    const promocode = agentPromoCode.value;
    if (promocode !== '') {
        validAgentPromoCode = validateAgentPromoCode(promocode);
    } else {
        validAgentPromoCode = false;
    }
}
// Add class
function addClass (element, classname) {
    const el = document.querySelector(element);
    if (el !== null) {
        el.classList.add(classname);
    }
}
// Remove class
function removeClass (element, classname) {
    const els = document.querySelectorAll(element);
    if (els !== null) {
        for (let i = 0; i < els.length; i++) {
            els[i].classList.remove(classname);
        }
    }
}

/**
 * Validate the entered promocode
 * @returns {boolean} Returns true when the promocode is valid
 * @param {} promocode
 */
function validateAgentPromoCode (promocode) {
    const regPromoCode = new RegExp('^[A-Za-z0-9]*$');
    return regPromoCode.test(promocode);
}

/**
 * button state change
 */
function changeButtonState () {
    const applyButton = document.querySelector('.apply-btn');
    if (validAgentPromoCode) {
        applyButton.removeAttribute('disabled');
    } else {
        applyButton.setAttribute('disabled', 'disabled');
    }
}
