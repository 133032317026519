let observer;

function init () {
    const MutationObserver = window.MutationObserver || window.WebKitMutationObserver || window.MozMutationObserver;
    /**
     * Mutation Observer to monitor all the DOm changes
     * @type {MutationObserver}
     */

    observer = new MutationObserver(function (mutationList) {
        for (let i = 0; i < mutationList.length; i++) {
            if (typeof mutationList[i] !== 'undefined' && mutationList[i].addedNodes.length >= 1) {
                stickyFooter();
                break;
            }
        }
    });

    /**
     * Configuration of the Mutation Observaion. type of the DOM need to be detected and fired.
     * @type {{subtree: boolean, attributes: boolean, childList: boolean, characterData: boolean, attributeFilter: [string, string]}}
     */
    const mutationObserverConfig = {
        attributes: false,
        childList: true,
        characterData: false,
        subtree: true,
    };

    // define what element should be observed by the observer
    // and what types of mutations trigger the callback
    if (typeof observer !== 'undefined') {
        observer.observe(document.body, mutationObserverConfig);
    }
}

document.addEventListener('DOMContentLoaded', function () {
    stickyFooter();
    init();
});

document.addEventListener('scroll', stickyFooter());

window.onresize = function () {
    stickyFooter();
};

function stickyFooter () {
    const stickyFooter = document.querySelector('.sticky-footer');
    doStickyFooter(stickyFooter);
}

function doStickyFooter (stickyFooter) {
    let viewPortHeight = window.innerHeight;
    const documentHeight = document.body.clientHeight;

    const footerContainer = document.querySelector('.footer');
    if (footerContainer) {
        footerContainer.style.position = 'static';
    }
    if (stickyFooter) {
        if (stickyFooter.style.position === 'absolute') {
            const stickyFooterHeight = stickyFooter.clientHeight;
            viewPortHeight = viewPortHeight - stickyFooterHeight;
        }
        if (viewPortHeight > (documentHeight)) {
            stickyFooter.style.position = 'absolute';
        } else {
            stickyFooter.style.position = 'relative';
        }
    }
}

const returnTop = document.querySelector('.return-to-top a');

const returnButton = document.querySelector('.return-to-top button');

if (returnTop || returnButton) {
    returnTop.addEventListener('click', function () {
        scrollTop();
    });
    returnTop.addEventListener('onkeypress', function (e) {
        if (e.keyCode === 13) {
            scrollTop();
        }
    });
}

/**
 * Scroll top function for footer scroll to top in mobile.
 */
function scrollTop () {
    if (window.pageYOffset !== 0) {
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
    }
}
