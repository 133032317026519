$(function () {
    $('.list-group li').click(function (e) {
        e.preventDefault();
        const $that = $(this);
        $that.parent().find('li').removeClass('active');
        $that.addClass('active');
    });
    function myFunction () {
        let input, filter, ul, li, a, i, txtValue;
        input = document.getElementById('myInput');
        filter = input.value.toUpperCase();
        ul = document.getElementById('myUL');
        li = ul.getElementsByTagName('li');
        for (i = 0; i < li.length; i++) {
            a = li[i];
            txtValue = a.textContent || a.innerText;
            if (txtValue.toUpperCase().indexOf(filter) > -1) {
                li[i].style.display = '';
            } else {
                li[i].style.display = 'none';
            }
        }
    }
    $('#myInput').keyup(myFunction);
});
