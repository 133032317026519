$(function () {
    $('#vscop-modal-demo').modal({
        show: true,
        backdrop: 'static',
        keyboard: false
    });
    $('#keep-landline').modal({
        show: true,
        backdrop: 'static',
        keyboard: false
    });
    const vscopCheckbox = document.getElementById('vscop-checkbox');
    if (vscopCheckbox) {
        vscopCheckbox.addEventListener('change', onVscopChange);
    }
});

function onVscopChange () {
    if (this.checked) {
        document.getElementById('vscop-btn').disabled = false;
    }
    else {
        document.getElementById('vscop-btn').disabled = true;
    }
}
