/* eslint no-useless-escape: 'warn' */
let validLandLine = false;
let validPostCode = false;
let postCode;
let landLine;
let isJLCustomerEnabled = 'false';
document.addEventListener('DOMContentLoaded', function () {
    postCode = document.querySelector('.postcode');
    landLine = document.querySelector('.landline');
    if (postCode !== null) {
        postCode.addEventListener('focus', function () {
            removeClass('.postcode-section .error-class', 'show-error-class');
        });
        postCode.addEventListener('input', function () {
            onInputChangePostCode();
        });
        postCode.addEventListener('blur', function () {
            onBlurPostCode();
        });
    }
    if (landLine !== null) {
        isJLCustomerEnabled = landLine.getAttribute('data-interaction-isjlcustomerenabled');
        landLine.addEventListener('focus', function () {
            removeClass('.landline-section .error-class', 'show-error-class');
        });
        landLine.addEventListener('input', function () {
            onInputChangePhone();
        });
        landLine.addEventListener('blur', function () {
            onBlurPhone();
        });
    }
    setTimeout(function () {
        if (postCode !== null && landLine !== null) {
            onInputChangePostCode();
            onInputChangePhone();
        }
    });
});
function onBlurPostCode () {
    validateForm();
    if (!validPostCode) {
        showPostcodeErrorMsg();
    } else {
        postCode.setAttribute('aria-invalid', false);
        postCode.removeAttribute('aria-describedby');
        removeErrorMsg('.postcode', '.postcode-section');
    }
}
function onBlurPhone () {
    validateForm();
    if (!validLandLine) {
        showLandLineErrorMsg();
    } else {
        removeErrorMsg('.landline', '.landline-section');
        landLine.setAttribute('aria-invalid', false);
        landLine.removeAttribute('aria-describedby');
    }
}

function onInputChangePhone () {
    const phone = landLine.value;
    if (phone !== '') {
        validLandLine = validateLandLine(phone);
    } else {
        validLandLine = isJLCustomerEnabled !== 'true';
    }
    changeButtonState(phone);
}

function onInputChangePostCode () {
    const postcode = postCode.value;
    const phone = landLine.value;
    if (postcode !== '') {
        validPostCode = validatePostCode(postcode);
    } else {
        validPostCode = false;
    }
    changeButtonState(phone);
}

function validateForm () {
    const postcode = postCode.value;
    const phone = landLine.value;
    if (postcode !== '') {
        validPostCode = validatePostCode(postcode);
    } else {
        validPostCode = false;
    }
    validLandLine = validateLandLine(phone);
}

function showPostcodeErrorMsg () {
    postCode.classList.add('invalid-error-class');
    postCode.setAttribute('aria-invalid', true);
    if (postCode.value === '') {
        postCode.setAttribute('aria-describedby', 'no-postcode');
        addClass('.postcode-section .error-class.empty-postcode', 'show-error-class');
    } else {
        postCode.setAttribute('aria-describedby', 'wrong-postcode');
        removeClass('.postcode-section .error-class.empty-postcode', 'show-error-class');
        addClass('.postcode-section .error-class.invalid-postcode', 'show-error-class');
    }
}

function addClass (element, classname) {
    const el = document.querySelector(element);
    if (el !== null) {
        el.classList.add(classname);
    }
}

function removeClass (element, classname) {
    const els = document.querySelectorAll(element);
    if (els !== null) {
        for (let i = 0; i < els.length; i++) {
            els[i].classList.remove(classname);
        }
    }
}

function showLandLineErrorMsg () {
    landLine.classList.add('invalid-error-class');
    landLine.setAttribute('aria-invalid', true);
    if (landLine.value === '') {
        landLine.setAttribute('aria-describdeby', 'no-landline');
        addClass('.landline-section .error-class.empty-landline', 'show-error-class');
    } else {
        landLine.setAttribute('aria-describdeby', 'wrong-landline');
        removeClass('.landline-section .error-class.empty-landline', 'show-error-class');
        addClass('.landline-section .error-class.invalid-landline', 'show-error-class');
    }
}

function removeErrorMsg (element, sectionName) {
    $(element).removeClass('invalid-error-class');
    $(element).addClass('focus-class');
    removeClass(sectionName + ' .error-class', 'show-error-class');
}

/**
 * Validate the entered postcode
 * @param {} postcode
 */
function validatePostCode (postcode) {
    const regPostcode = new RegExp('^(([Gg][Ii][Rr] 0[Aa]{2})|((([A-Za-z][0-9]{1,2})|(([A-Za-z][A-Ha-hJ-Yj-y][0-9]{1,2})|(([A-Za-z][0-9][A-Za-z])|([A-Za-z][A-Ha-hJ-Yj-y][0-9]?[A-Za-z])))) {0,1}[0-9][A-Za-z]{2}))$'); // NOSONAR
    return regPostcode.test(postcode);
}

/**
 * Validate land line
 * @returns {boolean} Returns true when the land line is valid
 * @param phone
 */
function validateLandLine (phone) {
    // eslint-disable-next-line no-useless-escape
    const landLineRegex = new RegExp('^\s*0([1,2])([0-9]\s*){8,9}$');
    if (isJLCustomerEnabled === 'true') {
        return landLineRegex.test(phone);
    }
    return (landLineRegex.test(phone) || phone === '');
}

function changeButtonState (phone) {
    const checkNowButton = document.querySelector('.validate-item');
    if (isJLCustomerEnabled === 'false') {
        if ((validLandLine && validPostCode) || (validPostCode && phone.length <= 0)) {
            checkNowButton.removeAttribute('disabled');
            checkNowButton.setAttribute('data-interaction-state', 'Enabled');
        } else {
            checkNowButton.setAttribute('disabled', 'disabled');
            checkNowButton.setAttribute('data-interaction-state', 'Disabled');
        }
    } else {
        if (validLandLine && validPostCode) {
            checkNowButton.removeAttribute('disabled');
            checkNowButton.setAttribute('data-interaction-state', 'Enabled');
        } else {
            checkNowButton.setAttribute('disabled', 'disabled');
            checkNowButton.setAttribute('data-interaction-state', 'Disabled');
        }
    }
}
