import ElementStateReporter from './ElementStateReporter';

/**
 * Initialise element state reporter for one list
 * @param  {string} containerSelector
 *         The container selector to watch for mutations. This will also
 *         form part of the query selector for elements to watch/position.
 * @param  {string} elemSelector
 *         A selector string for elements that need position calculating
 * @param  {string} positionAttr
 *         A string for the attribute for position tracking
 * @return {void}
 */
// eslint-disable-next-line func-style
const initEsr = function (containerSelector, elemSelector, positionAttr) {
    const container = document.querySelector(containerSelector);

    if (container !== null) {
        const elementStateReporter = new ElementStateReporter(
            container,
            elemSelector,
            positionAttr || null
        );

        elementStateReporter.init();
    }
};

window.addEventListener('load', () => {
    initEsr('.root', '.pn-module-section');
});
