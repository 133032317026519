document.addEventListener('DOMContentLoaded', function () {
    var accHeading = document.getElementsByClassName('accordion-item-heading');
    if (accHeading) {
        for (var i = 0; i < accHeading.length; i++) {
            accHeading[i].addEventListener('click', toggleItem, false);
            accHeading[i].addEventListener('onkeypress', function (e) {
                if (e.keyCode === 13) {
                    toggleItem();
                }
            });
        }
    }
});

function toggleItem () {
    var itemClass = this.parentNode.className;
    if (itemClass === 'accordion-container close') {
        this.parentNode.className = 'accordion-container open';
        this.setAttribute('aria-expanded', 'true');
        document.querySelector('#' + this.id + ' .rates-text-toggle').innerHTML = 'Hide';
    }
    if (itemClass === 'accordion-container open') {
        this.parentNode.className = 'accordion-container close';
        this.setAttribute('aria-expanded', 'false');
        document.querySelector('#' + this.id + ' .rates-text-toggle').innerHTML = 'Show';
    }
}
