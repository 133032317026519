/* eslint no-useless-escape: "warn" */
let keepLandLine;
document.addEventListener('DOMContentLoaded', function () {
    keepLandLine = document.querySelector('.keep-landline');
    if (keepLandLine !== null) {
        keepLandLine.addEventListener('focus', function () {
            keepLandLineFocus();
        });

        keepLandLine.addEventListener('blur', function () {
            keepLandLineBlur();
        });
        keepLandLine.addEventListener('keyup', function () {
            validateLandlineForm();
        });
    }
});

function keepLandLineFocus () {
    keepLandLine.style.borderColor = '#005da1';
    removeClass('.landline-section .error-class', 'show-error-class');
}

function keepLandLineBlur () {
    keepLandLine.style.borderColor = '#9e9e9e';
    removeClass('.landline-section .error-class', 'show-error-class');
    validateLandlineForm();
}

function validateLandlineForm () {
    if (keepLandLine.value === '') {
        keepLandLine.style.borderColor = 'red';
        addClass('.landline-section .error-class', 'show-error-class');
    } else {
        validateLandLine();
    }
}

function addClass (element, classname) {
    const el = document.querySelector(element);
    if (el !== null) {
        el.classList.add(classname);
    }
}

/**
 * Validate land line
 * @param strLandline Land line (assumes spaces have been removed)
 * @returns {boolean} Returns true when the land line is valid
 */
function validateLandLine () {
    let phone = keepLandLine.value;
    const regSpecialCharacter = new RegExp('[A-Z][a-z][!@#$%^&*()_+-=[]{};\':"\\|,.<>/?]/');
    const reg2xx = new RegExp('^2(?:0[378]|3[89]|4[7]|8[2-46-9]|9[2])');
    const reg11x1x1 = new RegExp('^1(?:(?:1(?:[3-8])|[2-69]1))');
    const reg18 = '^1(?:2(?:0(?:46[1-4]|87[2-9])|545[1-79]|76(?:2\\d|3[1-8]|6[1-6])|9(?:7(?:2[0-4]|3[2-5])|8(?:2[2-8]|7[0-4789]|8[345])))|3(?:638[2-5]|647[23]|8(?:47[04-9]|64[015789]))|4(?:044[1-7]|20(?:2[23]|8\\d)|6(?:0(?:30|5[2-57]|6[1-8]|7[2-8])|140)|8(?:052|87[123]))|5(?:24(?:3[2-79]|6\\d)|276\\d|6(?:26[06-9]|686))|6(?:06(?:4\\d|7[4-79])|295[567]|35[34]\\d|47(?:24|61)|59(?:5[08]|6[67]|74)|955[0-4]|977[23])|7(?:26(?:6[13-9]|7[0-7])|442\\d|50(?:2[0-3]|[3-68]2|76))|8(?:27[56]\\d|37(?:5[2-5]|8[239])|84(?:3[2-58]))|9(?:0(?:0(?:6[1-8]|85)|52\\d)|3583|4(?:66[1-8]|9(?:2[01]|81))|63(?:23|3[1-4])|9561))|^176888[234678]'; // NOSONAR
    const reg19 = '^1(?:2(?:0[024-9]|2[3-9]|3[3-79]|4[1-689]|[58][02-9]|6[0-4789]|7[013-9]|9\\d)|3(?:0\\d|[25][02-9]|3[02-579]|[468][0-46-9]|7[1235679]|9[24578])|4(?:0[03-9]|2[02-5789]|[37]\\d|4[02-69]|5[0-8]|[69][0-79]|8[0-5789])|5(?:0[1235-9]|2[024-9]|3[0145689]|4[02-9]|5[03-9]|6\\d|7[0-35-9]|8[0-468]|9[0-5789])|6(?:0[034689]|2[0-689]|[38][013-9]|4[1-467]|5[0-69]|6[13-9]|7[0-8]|9[0124578])|7(?:0[0246-9]|2\\d|3[023678]|4[03-9]|5[0-46-9]|6[013-9]|7[0-35-9]|8[024-9]|9[02-9])|8(?:0[35-9]|2[1-5789]|3[02-578]|4[0-578]|5[124-9]|6[2-69]|7\\d|8[02-9]|9[02569])|9(?:0[02-589]|2[02-689]|3[1-5789]|4[2-9]|5[0-579]|6[234789]|7[0124578]|8\\d|9[2-57]))'; // NOSONAR

    // if any special character and alphabets
    const validateSpecialCharacter = regSpecialCharacter.test(phone);
    if (validateSpecialCharacter) {
        keepLandLine.style.borderColor = 'red';
        addClass('.landline-section .error-class', 'show-error-class'); // invalid UK Phone Number
        addAttribute('.check-landline');
    }
    // Remove any non-numeric characters after special charecter and alphabets check is done
    // phone = phone.replace(/\D/g, '');
    // Replace +44 (0) entry with 0
    if (phone.match('^\\+?\\s*44\\s*(?:\\(?0\\)?)?\\s*')) {
        phone = phone.replace(/\+?\s*44\s*(?:\(?0\)?)?\s*/, '0');
        phone = phone.slice(1);
    }

    if (phone.match('^0') && (phone.length === 11 || phone.length === 10)) {
        phone = phone.slice(1);
    }

    if (phone.length === 10) {
        if (phone.match(reg2xx) || phone.match(reg11x1x1) || phone.match(reg19)) {
            if (!validateSpecialCharacter) {
                // valid UK Phone Number
                removeShowError();
            } else {
                // invalid UK Phone Number
                addShowError();
            }
        } else {
            addShowError();
            addAttribute('.check-landline');// invalid UK Phone Number
        }
    } else if (phone.length === 9) {
        if (phone.match(reg18) && !validateSpecialCharacter) {
            removeShowError();
        } else {
            addShowError();
            addAttribute('.check-landline');// invalid UK Phone Number
        }
    } else {
        if (phone.length === 0) {
            keepLandLine.value = '';
            removeShowError();
        } else {
            addShowError();
            if (phone.length > 0 && phone.length < 9) {
                addAttribute('.check-landline');
            }
        }
    }
}

function addShowError () {
    keepLandLine.style.borderColor = 'red';
    addClass('.landline-section .error-class', 'show-error-class');
}

function removeShowError () {
    keepLandLine.style.borderColor = '#9e9e9e';
    removeClass('.landline-section .error-class', 'show-error-class');
    removeAttribute('.check-landline');
}

function removeAttribute (element) {
    const el = document.querySelector(element);
    if (el !== null) {
        el.removeAttribute('disabled');
    }
}

function addAttribute (element) {
    const el = document.querySelector(element);
    if (el !== null) {
        el.setAttribute('disabled', 'disabled');
    }
}

function removeClass (element, classname) {
    const els = document.querySelectorAll(element);
    if (els !== null) {
        for (let i = 0; i < els.length; i++) {
            els[i].classList.remove(classname);
        }
    }
}
