// for old IE
import $ from 'jquery';

// bring our supported browsers up to ES2015 compliance.
// note: babel-preset-env makes this a "smart" import
// which only pulls in the polyfills missing from our target
// browsers. see the comments in .babelrc for more details
// import 'babel-polyfill';
// load the Bootstrap JS, which requires global.jQuery.
// XXX don't use `import` here as imports are hoisted, so
// the bootstrap JS would be loaded before we
global.jQuery = global.$ = $;

require('bootstrap-sass');
require('picturefill/dist/picturefill');
// our custom data-* attributes and hooks for classes etc.
require('./jquery/header');
require('./jquery/section_container');
require('./jquery/footer');
require('./jquery/tooltip');
require('./jquery/list');
require('./jquery/let-get-started');
require('./jquery/hero-banner');
require('./jquery/accordion');
require('./jquery/order-basket');
require('./jquery/vscop');
require('./jquery/minimal-header');
require('./jquery/keep-landline-form');
require('./jquery/spinner');
require('./jquery/agent-promo-code');
require('./jquery/css-table');
require('./init');
