import debounce from 'lodash.debounce';

import {
    setElemAttribute,
    getCoordinates,
    sortElementsByCoordinates
} from './utilities';

/**
 * Consult README.md for compilation/transpilation instructions
 * Used for analytics reporting on state and position of elements in the DOM
 * Adds state/position reporting onto elements.
 * Returned values used for analytics tracking.
 * Actual tracking is managed through GTM.
 */
export default class ElementStateReporter {
    /**
     * @param  {element} container
     *         The container element to watch for mutations. This will also
     *         form part of the query selector for elements to watch/position
     * @param  {string} elemSelector
     *         A selector string for elements that need position calculating
      * @param  {string} positionAttr
     *         A string for the attribute for position tracking
     * @return {void}
     */
    constructor (container, elemSelector, positionAttr) {
        if (!container || !elemSelector) {
            return;
        }

        this.container = container;
        this.elemSelector = elemSelector;
        this.positionAttr = positionAttr || 'data-position';

        this.elemPositionParse = this.elemPositionParse.bind(this);
    }

    /**
     * Creates a mutation observer to watch for changes to the order
     * of elements within the DOM
     *
     * @return {void}
     */
    init () {
        this.setupListener();
        this.setupMutationObserver();
        this.elemPositionParse();

        // Set up the dataLayer
        window.dataLayer = window.dataLayer || [];

        window.dataLayer.push({
            elementStateReporterInitialised: true,
        });
    }

    /**
     * Trigger a recalculation of the div positions when resizing has stopped
     *
     * @return {void}
     */
    setupListener () {
        window.addEventListener('resize', debounce(
            this.elemPositionParse, 500
        ));
    }

    /**
     * Configure, add and watch with a mutation observer
     * Note: This will fire when any node within the specified container is
     * mutated. Currently this seems to only fire after page load when the
     * mobile nav is added on small screens. So essentially useless
     *
     * @return {void}
     */
    setupMutationObserver () {
        const mutationObserverConfig = {
            attributes: true,
            childList: true,
            characterData: false,
            attributeFilter: ['style', 'class'],
            subtree: true,
        };

        const mutationObserver = new MutationObserver(this.elemPositionParse);

        mutationObserver.observe(this.container, mutationObserverConfig);
    }

    /**
     * @param  {array} rowArray    array of items to loop through
     * @param  {number} rowNum  the number to assign to the element
     *
     * @return {void}
     */
    assignDataVars (rowArray, rowNum) {
        for (let i = 0; i < rowArray.length; i += 1) {
            const newAssignmentNum = (rowArray.length === 1) ? rowNum : `${rowNum}.${i + 1}`;
            setElemAttribute(
                rowArray[i],
                this.positionAttr,
                newAssignmentNum
            );
        }
    }

    /**
     * Work out what position
     *
     */
    elemPositionParse () {
        const elemsToSort =
            [...this.container.querySelectorAll(this.elemSelector)];

        // Remove attr here in case any elements are hidden.
        // We don't want them to have a position if that is true.
        const visibleElems = elemsToSort.filter(item => {
            if (window.getComputedStyle(item).display !== 'none' && window.getComputedStyle(item).visibility !== 'hidden') {
                return true;
            }
            item.removeAttribute(this.positionAttr);
            return false;
        });

        const sortedElems = sortElementsByCoordinates(visibleElems);

        let rowArray = [];
        let rowNumber = 1;

        for (let i = 0; i < sortedElems.length; i += 1) {
            const lastLoopIteration = (i === sortedElems.length - 1);
            const currentItem = sortedElems[i];
            const nextItem = sortedElems[i + 1];
            const currentItemPosition = getCoordinates(currentItem);

            let nextItemPosition = null;

            rowArray.push(currentItem);

            if (!lastLoopIteration) {
                nextItemPosition = getCoordinates(nextItem);
            }

            if (lastLoopIteration || nextItemPosition.top > currentItemPosition.top) {
                this.assignDataVars(rowArray, rowNumber);
                rowNumber += 1;
                rowArray = [];
            }
        }
    }
}
