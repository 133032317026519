$(document).ready(function () {
    $('.order-basket-details').click(function () {
        $('.overlay-basket').css('display', 'block');
        $('.basket-main-content').css('display', 'none');
        $('.close-item').removeClass('hide');
        $('.rental-container').addClass('hide');
    });
    $('.basket-checkout').click(function () {
        $('.basket-main-content').css('display', 'block');
        $('.overlay-basket').css('display', 'none');
        $('.rental-container').removeClass('hide');
        $('.close-item').addClass('hide');
        if (window.pageYOffset !== 0) {
            document.body.scrollTop = 0;
            document.documentElement.scrollTop = 0;
        }
    });
});
