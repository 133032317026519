document.addEventListener('DOMContentLoaded', function () {
    $('.popover-trigger').on('click', function () {
        $('.popover-trigger').not($(this)).popover('hide');
        $(this).popover('toggle');
    }).blur(function () {
        $(this).popover('hide');
    });

    $('.popover-trigger').on('keypress', function () {
        if (event.keyCode === 13) {
            $('.popover-trigger').not($(this)).popover('hide');
            $(this).popover('toggle');
        }
    }).blur(function () {
        $(this).popover('hide');
    });
    // event triggers just before the popover is hidden
    $('[data-toggle=\'popover\']').on('hide.bs.popover', function () {
        const toolIcon = $(this).data('icon');
        $(this).removeClass('icon icon_24_filled_functional_warning').addClass(toolIcon);
    }).on('show.bs.popover', function () {
        const toolIcon = $(this).data('icon');
        $(this).removeClass(toolIcon).addClass('icon icon_24_filled_functional_warning');
    });
    // click anywhere outside and the popover is closed
    $(document).on('click', function (e) {
        $('[data-toggle="popover"]').each(function () {
            // did not click a popover toggle or popover
            if ($(e.target).data('toggle') !== 'popover' && $(e.target).parents('.popover.in').length === 0) {
                $(this).popover('hide');
            }
        });
    });
    addUniqueIdstoTooltips();
    addAriaDescribedby();
});

// add unique ids to all the mobile view tooltips on the page
function addUniqueIdstoTooltips () {
    const tooltipMobileContent = document.getElementsByClassName('tooltip-mobile-content');
    const tooltipMobileToggle = document.getElementsByClassName('tooltip-mobile-toggle');
    if (tooltipMobileContent.length > 0 && tooltipMobileToggle.length > 0) {
        for (let i = 0; i < tooltipMobileContent.length; i++) {
            tooltipMobileContent[i].id = 'tooltip-mobile-' + i;
        }
        for (let j = 0; j < tooltipMobileToggle.length; j++) {
            tooltipMobileToggle[j].setAttribute('href', '#tooltip-mobile-' + j);
            tooltipMobileToggle[j].setAttribute('aria-controls', '#tooltip-mobile-' + j);
        }
    }
}

/**
 * Add accessibility attribute to the popovers
 */
function addAriaDescribedby () {
    const popovers = document.querySelectorAll('[data-toggle=\'popover\']');
    if (popovers.length > 0) {
        for (let k = 0; k < popovers.length; k++) {
            popovers[k].setAttribute('tabindex', 0);
            popovers[k].setAttribute('role', 'button');
            if (!(popovers[k].classList.contains('incentive-popover'))) {
                popovers[k].setAttribute('aria-label', 'help');
            }
        }
    }
}
