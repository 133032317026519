$('.navbar-toggle').click(function () {
    $('.toggle-icons').toggleClass('icon_32_close icon_32_menu');
    $('.navbar-toggle').attr('data-interaction-state', $(this).attr('data-interaction-state') === 'open' ? 'close' : 'open');
    ($('.navbar-toggle').attr('data-interaction-state') === 'open') ? $('.navbar-toggle').addClass('close-style') : $('.navbar-toggle').removeClass('close-style');
    $('.nav-menu-title').toggleClass('hidden');
    $('.close-text').toggleClass('hidden');
});

const width = window.innerWidth;
if (width >= 1200) {
    $('.dropdown').each(function () {
        $(this).hover(function () {
            $(this).find($('.dropdown-menu')).css('display', 'block');
        });
        $(this).mouseleave(function () {
            $(this).find($('.dropdown-menu')).css('display', 'none');
        });
    });

    $('.dropdown').each(function () {
        $(this).find($('a.dropdown-toggle')).focusin(function () {
            $(this).siblings($('.dropdown-menu')).css('display', 'block');
        });
        $(this).find($('.dropdown-menu li:last-child a')).focusout(function () {
            $(this).parent().parent($('.dropdown-menu')).css('display', 'none');
        });
        $(this).find($('.dropdown-toggle')).on('keydown keyup', function (e) {
            if (e.which === 9 && e.shiftKey) {
                $(this).siblings($('.dropdown-menu')).css('display', 'none');
            }
        });
    });
}
