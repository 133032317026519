/**
 * Set Attribute is used to find all the attribute in the html and update the value
 * @param paddingAttribute
 * @param marginAttribute
 * @param imageAttribute
 * @param heightAttribute
 */
function setAttribute (paddingAttribute, marginAttribute, imageAttribute, heightAttribute) {
    const padding = Array.prototype.slice.call(document.querySelectorAll(`[${paddingAttribute}]`));
    padding.forEach(function (element) {
        element.style.padding = element.getAttribute(paddingAttribute);
    });

    const margin = Array.prototype.slice.call(document.querySelectorAll(`[${marginAttribute}]`));
    margin.forEach(function (element) {
        element.style.margin = element.getAttribute(marginAttribute);
    });

    const images = Array.prototype.slice.call(document.querySelectorAll('[data-bg-image-desktop]'));
    const width = window.innerWidth;
    images.forEach(function (element) {
        if (width <= 1199 && width >= 960 && (element.getAttribute('data-bg-image-tablet-lrg') === null)) {
            setBackgroundImage(element, element.getAttribute('data-bg-image-desktop'));
        } else if (width <= 959 && width >= 768 && (element.getAttribute('data-bg-image-tablet') === null)) {
            setBackgroundImage(element, element.getAttribute('data-bg-image-desktop'));
        } else if (width <= 767 && width >= 600 && (element.getAttribute('data-bg-image-mobile-lrg') === null)) {
            setBackgroundImage(element, element.getAttribute('data-bg-image-desktop'));
        } else if (width <= 599 && (element.getAttribute('data-bg-image-mobile') === null)) {
            setBackgroundImage(element, element.getAttribute('data-bg-image-desktop'));
        }
    });
    const image = Array.prototype.slice.call(document.querySelectorAll(`[${imageAttribute}]`));
    image.forEach(function (element) {
        setBackgroundImage(element, element.getAttribute(imageAttribute));
    });

    const sectionHeight = Array.prototype.slice.call(document.querySelectorAll(`[${heightAttribute}]`));
    sectionHeight.forEach(function (element) {
        element.style.height = element.getAttribute(heightAttribute);
        element.style.overflow = 'hidden';
    });
}

/**
 * If the image is not full width, background will be set to the child div to make margin visible.
 * @param element
 * @param url
 */
function setBackgroundImage (element, url) {
    if (!element.className.match('not-full-width')) {
        element.style.background = 'url(' + url + ') no-repeat';
        element.style.backgroundSize = '100% 100%';
    } else {
        const childElement = element.querySelector('.section-container');
        childElement.style.background = 'url(' + url + ') no-repeat';
        childElement.style.backgroundSize = '100% 100%';
    }
}

/**
 * based on the breakpoint, we will call the particular attributes
 */
function checkPadding () {
    const width = window.innerWidth;
    if (width >= 1200) {
        setAttribute('data-col-padding-desktop', 'data-col-margin-desktop',
            'data-bg-image-desktop', 'data-height-desktop');
    } else if (width <= 1199 && width >= 960) {
        setAttribute('data-col-padding-tablet', 'data-col-margin-tablet',
            'data-bg-image-tablet-lrg', 'data-height-tablet-lrg');
    } else if (width <= 959 && width >= 768) {
        setAttribute('data-col-padding-tablet', 'data-col-margin-tablet',
            'data-bg-image-tablet', 'data-height-tablet');
    } else if (width <= 767 && width >= 600) {
        setAttribute('data-col-padding-mobile', 'data-col-margin-mobile',
            'data-bg-image-mobile-lrg', 'data-height-mobile-lrg');
    } else {
        setAttribute('data-col-padding-mobile', 'data-col-margin-mobile',
            'data-bg-image-mobile', 'data-height-mobile');
    }
}

function alignPosition () {
    // data-align-position="end"
    const dividerWeight = Array.prototype.slice.call(document.querySelectorAll('[data-align-position]'));
    dividerWeight.forEach(function (element) {
        const position = element.getAttribute('data-align-position');
        if (position === 'bottom') {
            element.style['align-self'] = 'flex-end';
        } else if (position === 'center') {
            element.style['align-self'] = 'center';
        } else {
            element.style['align-self'] = 'flex-start';
        }
    });
}

/**
 * function to add the border top width fof divider
 */

function dividerWeight () {
    const dividerWeight = Array.prototype.slice.call(document.querySelectorAll('[data-divider-weight]'));
    dividerWeight.forEach(function (element) {
        element.style['border-top-width'] = element.getAttribute('data-divider-weight');
    });
}

/**
 * Set the height based on the specification from the section container attribute
 */
function setHeight () {
    const sectionHeight = Array.prototype.slice.call(document.querySelectorAll('[data-height]'));
    sectionHeight.forEach(function (element) {
        const height = element.getAttribute('data-height');
        element.style.height = `${height}px`;
        element.style.overflow = 'hidden';
    });
}

function onLoadSectionContainer () {
    checkPadding();
    dividerWeight();
    setHeight();
    alignPosition();
}

$(document).ready(function () {
    onLoadSectionContainer();

    $(window).resize(function () {
        onLoadSectionContainer();
    });
});

document.addEventListener('DOMContentLoaded', function () {
    onLoadSectionContainer();
    loadDynamicSC();
});
function loadDynamicSC () {
    var MutationObserver = window.MutationObserver || window.WebKitMutationObserver || window.MozMutationObserver;
    // Select the node that will be observed for mutations
    var scNode = document.getElementsByClassName('header');
    // Options for the observer (which mutations to observe)
    var scConfig = { attributes: true, childList: true, subtree: true };
    // Callback function to execute when mutations are observed
    function scCallback (mutationsList, scObserver) {
        for (var mutation in mutationsList) {
            if (mutationsList[mutation].type === 'childList') {
                // calling alignmentCards for product card alignments.
                onLoadSectionContainer();
            }
            else if (mutationsList[mutation].type === 'attributes') {
                onLoadSectionContainer();
            }
        }
        scObserver.disconnect();
    }
    // Create an observer instance linked to the callback function
    var scObserver = new MutationObserver(scCallback);
    // Start observing the target node for configured mutations
    if (scNode.length > 0) {
        for (var i = 0; i < scNode.length; i++) {
            scObserver.observe(scNode[i], scConfig);
        }
    }
}
