$('#notification-green').on('click', function () {
    $('#notificationGreen').show();
    $('#notificationOrange').hide();
})

$('#notification-orange').on('click', function () {
    $('#notificationGreen').hide();
    $('#notificationOrange').show();
})
$('.notification-close').on('click', function () {
    $('.cart-notification').hide();
});
$('.notification-close').on('keypress', function (e) {
    if (e.keyCode === 13) {
        $('.cart-notification').hide();
    }
});
